<template>
  <section class="w-full">
    <h2 class="text-lg font-bold mb-4">独自アプリ</h2>
    <div
      class="w-full rounded-md bg-white py-8 md:px-32 flex justify-center flex-wrap gap-y-8 md:gap-y-8"
    >
      <div
        v-for="(miniApp, index) in miniAppList"
        :key="index"
        class="flex flex-col items-center justify-center"
      >
        <div
          v-if="isVisibleToThisStudent(miniApp)"
          class="w-24 md:w-32 flex flex-col items-center justify-center"
        >
          <a
            :href="miniApp.data?.url"
            :target="!miniApp.data?.isWebView ? '_blank' : '_self'"
            ><div
              class="relative cursor-pointer w-16 h-16 md:w-24 md:h-24 rounded-full bg-green"
            >
              <p
                v-if="
                  !miniApp.data?.iconUrl ||
                    !miniAppimageUrls[miniApp.data.iconUrl]
                "
                class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl md:text-4xl"
              >
                {{ miniApp.data?.name[0] }}
              </p>
              <img
                v-if="
                  miniApp.data?.iconUrl &&
                    miniAppimageUrls[miniApp.data.iconUrl]
                "
                :src="miniAppimageUrls[miniApp.data.iconUrl]"
                alt="miniAppIcon"
                class="cursor-pointer w-full h-full rounded-full object-cover"
              />
            </div>
          </a>
          <div class="mt-2 h-1/6 w-4/6 flex items-start justify-center">
            <a
              :href="miniApp.data?.url"
              :target="!miniApp.data?.isWebView ? '_blank' : '_self'"
              ><p class="cursor-pointer text-sm">
                {{ miniApp.data?.name }}
                <m-icon
                  v-if="!miniApp.data?.isWebView"
                  :type="'link'"
                  size="5"
                  class="cursor-pointer inline"
                />
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import store, { awaitStudentLoaded } from "@/store";
import { MiniApp } from "../../../types/entities/mini_app";

@Options({
  components: {
    MIcon
  }
})
export default class SubmissionsSection extends Vue {
  studentId = "";

  get miniAppList() {
    return store.state.miniAppList;
  }
  get miniAppimageUrls() {
    return store.state.miniAppimageUrls;
  }

  isVisibleToThisStudent(miniApp: MiniApp): boolean {
    const { data } = miniApp;
    if (!data) {
      return false;
    }
    if (data.isDisplayNone) {
      return false;
    }

    if (data.distributionType === "all") {
      return true;
    }

    // targetStudnetIdsが設定されていない時は表示する
    if (data.targetStudentIds === undefined || data.targetStudentIds === null) {
      return true;
    }

    if (!Array.isArray(data.targetStudentIds)) {
      alert("アプリの読み込みに失敗しました");
      throw new Error("表示対象の生徒IDが配列の形式になっていません");
    }

    return data.targetStudentIds.includes(this.studentId);
  }

  async created(): Promise<void> {
    const student = await awaitStudentLoaded(store);
    this.studentId = student.ref.id;
  }
}
</script>
