
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import store, { awaitStudentLoaded } from "@/store";
import { MiniApp } from "../../../types/entities/mini_app";

@Options({
  components: {
    MIcon
  }
})
export default class SubmissionsSection extends Vue {
  studentId = "";

  get miniAppList() {
    return store.state.miniAppList;
  }
  get miniAppimageUrls() {
    return store.state.miniAppimageUrls;
  }

  isVisibleToThisStudent(miniApp: MiniApp): boolean {
    const { data } = miniApp;
    if (!data) {
      return false;
    }
    if (data.isDisplayNone) {
      return false;
    }

    if (data.distributionType === "all") {
      return true;
    }

    // targetStudnetIdsが設定されていない時は表示する
    if (data.targetStudentIds === undefined || data.targetStudentIds === null) {
      return true;
    }

    if (!Array.isArray(data.targetStudentIds)) {
      alert("アプリの読み込みに失敗しました");
      throw new Error("表示対象の生徒IDが配列の形式になっていません");
    }

    return data.targetStudentIds.includes(this.studentId);
  }

  async created(): Promise<void> {
    const student = await awaitStudentLoaded(store);
    this.studentId = student.ref.id;
  }
}
